<template>

    <v-container grid-list-md text-xs-center fluid mb-16>
        <h1>Romaneios</h1>
        <v-divider class="mb-4" />
        <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="itemsTotal" :loading="loading" class="elevation-1">

            <template v-slot:item.type="{ item }">
                {{ typeTitle(item) }}
            </template>

            <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt ? $moment(item.createdAt).format("DD/MM/YY HH:mm") : "-" }}
            </template>

            <template v-slot:item.ordersNumber="{ item }">
                <v-chip class="ma-0" outlined :color="item.ordersNumber > 0 && item.ordersNumber == item.ordersNumberOnFinish ? 'success' : 'grey'">
                    {{ item.ordersNumber ? item.ordersNumber : "-" }} / {{ item.ordersNumberOnFinish ? item.ordersNumberOnFinish : "-" }}
                </v-chip>
            </template>

            <template v-slot:item.action="{ item }">
                <div v-if="item.type == 'collect' || item.type == 'receive'">
                    <v-row v-if="itemToExport && itemToExport.id == item.id">
                        <v-btn class="ma-2" color="info" dark small @click="createPDF(item)">
                            PDF
                        </v-btn>
                        <DownloadCsv :data="csv" :name="`${item.code}.csv`" :labels="csvLabels" delimiter=";">
                            <v-btn class="ma-2" color="info" small>CSV</v-btn>
                        </DownloadCsv>
                    </v-row>
                    <v-row v-else>
                        <v-btn class="ma-2" color="info" dark small @click="get(item.id)">
                            Gerar Relatório <v-icon dark small right>picture_as_pdf</v-icon>
                        </v-btn>
                    </v-row>
                </div>
            </template>

        </v-data-table>
    </v-container>
</template>

<script>
import DownloadCsv from "vue-json-csv";
import packingListPDF from "@/pdf/packingList";

export default {
    components: {
        DownloadCsv,
    },

    data() {
        return {
            packingListPDF,
            itemsTotal: 0,
            items: [],
            itemToExport: undefined,
            loading: true,
            options: { itemsPerPage: 10, page: 1 },
            headers: [
                { text: this.$t("#"), value: "id", align: "left", sortable: false },
                { text: this.$t("Tipo de Romaneio"), value: "type", align: "right", sortable: false },
                { text: this.$t("Código"), value: "code", align: "right", sortable: false },
                { text: this.$t("Romaneio"), value: "extraCode", align: "right", sortable: false },
                { text: this.$t("Loja"), value: "store", align: "right", sortable: false },
                { text: this.$t("Usuário"), value: "createdBy", align: "center", sortable: false },
                { text: this.$t("Data"), value: "createdAt", align: "center", sortable: false },
                { text: this.$t("Número de pacotes informados/bipados"), value: "ordersNumber", align: "right", sortable: false },
                { text: this.$t("Ações"), value: "action", align: "right", sortable: false },
            ],
            csvLabels: {
                order: "Pedido",
                key: "Nota fiscal",
                store: "Loja",
                volumeCode: "Código Volume",
                collectedDate: "Data Coleta",
                collectedBy: "Coletado Por",
                receivedDate: "Data do recebimento",
                receivedBy: "Recebido por",
                devolution: "Número de devoluções",
            },
        };
    },

    created() {
        this.getDataFromApi();
    },

    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },

    computed: {
        csv() {
            if (this.itemToExport && this.itemToExport.result && this.itemToExport.result.packages && this.itemToExport.result.packages.length > 0) {
                console.log(this.itemToExport.result.packages)
                return this.itemToExport.result.packages.map((i) => {
                    return {
                        order: i.order,
                        key: i.key,
                        store: i.store,
                        volumeCode: i.volumeCode,
                        collectedDate: i.collectedDate ? this.$moment(i.collectedDate).format("DD/MM/YYYY HH:mm") : null,
                        collectedBy: i.collectedBy,
                        receivedDate: i.receivedDate ? this.$moment(i.receivedDate).format("DD/MM/YYYY HH:mm") : null,
                        receivedBy: i.receivedBy,
                        devolution: i.returnList && i.returnList.length > 0 ? i.returnList.length : null,
                    };
                });
            } else {
                this.$eventHub.$emit("msgError", this.$t("Nenhum dado para exportar"));
            }
            return [];
        },
    },

    methods: {
        async get(id) {
            this.itemToExport = undefined;
            await this.$http
                .get(`checklists/${id}`)
                .then(async (result) => {
                    this.itemToExport = { result, id };
                })
                .catch(() => {
                    this.$eventHub.$emit("msgError", this.$t("Erro ao buscar informações para documento"));
                });

        },
        async createPDF(item) {
            await this.get(item.id);

            if (this.itemToExport != undefined) {
                let doc = await packingListPDF.create(this.itemToExport.result);
                // doc.output("report.pdf");
                window.open(doc.output("bloburl"));
            }

        },
        typeTitle(item) {
            switch (item.type) {
                case "collect":
                    return "Coleta"
                case "receive":
                    return "Recebimento R2PP"
                case "return":
                    return "Devolução"
                default:
                    return item.type;
            }
        },
        getDataFromApi() {
            this.loading = true
            this.$http
                .get(`v2/checklists?skip=${this.options.itemsPerPage * (this.options.page - 1)}&limit=${this.options.itemsPerPage}`)
                .then((result) => {
                    this.items = result.items
                    this.itemsTotal = result.count
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                });
        },
    }
};
</script>